import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminConstraintRoute from './AdminConstraintRoute';
import GuestConstraintRoute from './GuestConstraintRoute';
import Root from '../pages/Root';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const DataUsers = React.lazy(() => import('../pages/Data/Users'));
const DataStudentNew = React.lazy(() => import('../pages/Data/Student/New'));
const DataStudentEdit = React.lazy(() => import('../pages/Data/Student/Edit'));
const DataSessions = React.lazy(() => import('../pages/Data/Sessions'));
const DataSessionsNew = React.lazy(() => import('../pages/Data/Sessions/New'));
const DataSession = React.lazy(() => import('../pages/Data/Session'));
const DataSessionIssues = React.lazy(
  () => import('../pages/Data/SessionIssues')
);
const DataHomeworks = React.lazy(() => import('../pages/Data/Homeworks'));
const DataTutoringSettlements = React.lazy(
  () => import('../pages/Data/TutoringSettlements')
);
const DataSubscriptions = React.lazy(
  () => import('../pages/Data/Subscriptions')
);
const DataTutoringSettlementRequests = React.lazy(
  () => import('../pages/Data/TutoringSettlementRequests')
);
const HrData = React.lazy(() => import('../pages/Hr/Data'));
const HrTutors = React.lazy(() => import('../pages/Hr/Tutors'));
const HrNewTutor = React.lazy(() => import('../pages/Hr/NewTutor'));
const HrTutorEdit = React.lazy(() => import('../pages/Hr/Tutor/Edit'));
const HrTutorShiftsTrial = React.lazy(
  () => import('../pages/Hr/Tutor/Shifts/Trial')
);
const HrTutorShiftsRegular = React.lazy(
  () => import('../pages/Hr/Tutor/Shifts/Regular')
);
const CsTrials = React.lazy(() => import('../pages/Cs/Trials'));
const CsMatchRequest = React.lazy(
  () => import('../pages/Cs/MatchRequests/MatchRequest')
);

const SettingsPassword = React.lazy(() => import('../pages/Settings/Password'));

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Root} />
      <GuestConstraintRoute path="/login" exact component={Login} />
      <AdminConstraintRoute path="/Dashboard" exact component={Dashboard} />
      <AdminConstraintRoute path="/data/users" exact component={DataUsers} />
      <AdminConstraintRoute
        path="/data/students/new"
        exact
        component={DataStudentNew}
      />
      <AdminConstraintRoute
        path="/data/students/:id/edit"
        exact
        component={DataStudentEdit}
      />
      <AdminConstraintRoute
        path="/data/sessions"
        exact
        component={DataSessions}
      />
      <AdminConstraintRoute
        path="/data/sessions/new"
        exact
        component={DataSessionsNew}
      />
      <AdminConstraintRoute
        path="/data/sessions/:id"
        exact
        component={DataSession}
      />
      <AdminConstraintRoute
        path="/data/session-issues"
        exact
        component={DataSessionIssues}
      />
      <AdminConstraintRoute
        path="/data/homeworks"
        exact
        component={DataHomeworks}
      />
      <AdminConstraintRoute
        path="/data/tutoring-settlements"
        exact
        component={DataTutoringSettlements}
      />
      <AdminConstraintRoute
        path="/data/tutoring-settlement-requests"
        exact
        component={DataTutoringSettlementRequests}
      />
      <AdminConstraintRoute
        path="/data/subscriptions"
        exact
        component={DataSubscriptions}
      />
      <AdminConstraintRoute path="/hr/data" exact component={HrData} />
      <AdminConstraintRoute path="/hr/tutors" exact component={HrTutors} />
      <AdminConstraintRoute
        path="/hr/tutors/new"
        exact
        component={HrNewTutor}
      />
      <AdminConstraintRoute
        path="/hr/tutors/:id/edit"
        exact
        component={HrTutorEdit}
      />
      <AdminConstraintRoute
        path="/hr/tutors/:id/shifts/trial"
        exact
        component={HrTutorShiftsTrial}
      />
      <AdminConstraintRoute
        path="/hr/tutors/:id/shifts/regular"
        exact
        component={HrTutorShiftsRegular}
      />
      <AdminConstraintRoute
        path="/settings/password"
        exact
        component={SettingsPassword}
      />
      <AdminConstraintRoute path="/cs/trials" exact component={CsTrials} />
      <AdminConstraintRoute
        path="/cs/match-requests/:id"
        exact
        component={CsMatchRequest}
      />
    </Switch>
  );
};

export default Routes;

/* 

General:

/login
/forgot-password
/reset-password


*/
