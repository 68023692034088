import React, { Suspense, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from '../../auth';
import AppLoading from '../../components/atoms/AppLoading';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const GuestConstraintRouteComponent = ({
  component: Component,
  ...rest
}: IProps) => {
  const { isLoggedIn } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn, history]);
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (Component) {
          return (
            <Suspense fallback={<AppLoading />}>
              <Component {...renderProps} />
            </Suspense>
          );
        }
        return <div />;
      }}
    />
  );
};

GuestConstraintRouteComponent.defaultProps = {
  exact: false,
};

const GuestConstraintRoute = React.memo<IProps>(GuestConstraintRouteComponent);

export default GuestConstraintRoute;
