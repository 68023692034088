import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppLoading from '../../components/atoms/AppLoading';
import { useAuth } from '../../auth';

const Root = () => {
  const history = useHistory();
  const { isLoggedIn, currentRole } = useAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/login');
    }
    if (isLoggedIn && currentRole) {
      if (currentRole === 'admin') {
        history.replace('/dashboard');
      }
    }
  }, [isLoggedIn, currentRole, history]);
  return <AppLoading />;
};

export default Root;
