import React, { useState, useEffect, createContext, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment-timezone';

const AuthContext = createContext<{
  isLoggedIn: boolean;
  firebaseUser: firebase.User | null;
  loaded: boolean;
  currentRole: string | null;
}>({
  isLoggedIn: false,
  firebaseUser: null,
  currentRole: null,
  loaded: false,
});

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        setUser(null);
        setRole(null);
        setLoaded(true);
        sessionStorage.removeItem('idToken');
        return;
      }
      const idTokenResult = await firebaseUser.getIdTokenResult();
      sessionStorage.setItem('idToken', idTokenResult.token);
      setUser(firebaseUser);
      setRole(idTokenResult?.claims?.role);
      setLoaded(true);
    });

    firebase.auth().onIdTokenChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        sessionStorage.removeItem('idToken');
        sessionStorage.setItem('idTokenChangedAt', moment().unix().toString());
        return;
      }
      const idTokenResult = await firebaseUser.getIdTokenResult();
      sessionStorage.setItem('idToken', idTokenResult.token);
      sessionStorage.setItem('idTokenChangedAt', moment().toISOString());
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!user,
        firebaseUser: user,
        loaded,
        currentRole: role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
