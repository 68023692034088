
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IUser": [
      "Tutor",
      "Student",
      "Admin",
      "Guest"
    ],
    "DisplayableError": [
      "UserError"
    ]
  }
};
      export default result;
    