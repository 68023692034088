/* eslint-disable no-console */
import firebase from 'firebase';
import React, { Suspense, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from '../../auth';
import AppLoading from '../../components/atoms/AppLoading';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const AdminConstraintRouteComponent = ({
  component: Component,
  ...rest
}: IProps) => {
  const { isLoggedIn, currentRole, loaded } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (loaded && !isLoggedIn) {
      history.push('/login');
    }
    if (
      loaded &&
      isLoggedIn &&
      (!currentRole || (currentRole && currentRole !== 'admin'))
    ) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          history.push('/', {
            state: {
              flash: {
                message: 'Unauthorized Route',
                type: 'error',
              },
            },
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          return <p>エラーが発生しました。</p>;
        });
    }
  }, [loaded, isLoggedIn, currentRole, history]);
  if (!loaded) {
    return <AppLoading />;
  }
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        return (
          <Suspense fallback={<AppLoading />}>
            <Component {...renderProps} />
          </Suspense>
        );
      }}
    />
  );
};

AdminConstraintRouteComponent.defaultProps = {
  exact: false,
};

const AdminConstraintRoute = React.memo<IProps>(AdminConstraintRouteComponent);

export default AdminConstraintRoute;
