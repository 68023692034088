import { ApolloClient, makeVar, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import introspectionResult from './introspection-result';

interface AuthState {
  isLoggedIn: boolean;
  currentRole: string | null;
}

export const authState = makeVar<AuthState>({
  isLoggedIn: false,
  currentRole: null,
});

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    // Type policy map
    Query: {
      fields: {
        authState: {
          read() {
            return authState();
          },
        },
      },
    },
  },
});

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string;
const WS_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT as string;

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: WS_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: {
      idToken: sessionStorage.getItem('idToken'),
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('idToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache,
});

export default client;
