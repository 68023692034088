import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as Loading } from './loading.svg';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#F5F6F7',
});

const AppLoading = ({ size = '80px' }) => {
  return (
    <Wrapper>
      <Loading height={size} width={size} />
    </Wrapper>
  );
};

export default AppLoading;
