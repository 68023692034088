import React from 'react';
import './tailwind.output.css';
import './App.scss';
import 'react-dates/lib/css/_datepicker.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import AppLoading from './components/atoms/AppLoading';
import { useAuth } from './auth';

const App = React.memo(() => {
  const { loaded } = useAuth();

  if (!loaded) return <AppLoading />;
  const routes = (
    <Router>
      <Routes />
    </Router>
  );
  return routes;
});

export default App;
